import React from 'react';
import { Grid, CardContent, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import StartDateCard from '../PortfolioViewComponents/StartDateComponent-Portfolio';
import ProjectedEndDateCard from '../PortfolioViewComponents/ProjectedEndDate-Portfolio';
import ProjectTypeCard from '../PortfolioViewComponents/ProjectTypeComponent-PortfolioView';
import SquareFootageCard from '../PortfolioViewComponents/SquareFootageComponent-Portfolio';
import PriceSFCard from '../PortfolioViewComponents/PricePerSFComponent-Portfolio';
import ProjectStageCardSmall from '../PortfolioViewComponents/ProjectStatusSmallComponent-Portfolio';
import ProjectDurationCard from '../PortfolioViewComponents/ProjectDurationComponent-Portfolio';

const useStyles = makeStyles((theme) => ({
  cardTitle: {
    fontSize: '0.85rem',
    color: '#003865'
  },
  cardContent: {
    fontSize: '0.90rem',
  },
  cardContentSmall: {
    fontSize: '0.25rem',
  }
}));

const PortfolioMasterProjectInfoCard = ({ projectData }) => {
  const classes = useStyles();
  const project = projectData?.project_data || projectData;

  return (
    <CardContent>
      <Grid container spacing={1} style={{ width: '100%', justifyContent: 'center' }}>
        <Grid item xs={4}>
          <Typography className={classes.cardTitle} style={{ textAlign: 'center' }}>
            Project Type
          </Typography>
          <ProjectTypeCard projectData={project} className={classes.cardContent}/>
        </Grid>
        <Grid item xs={4}>
          <Typography className={classes.cardTitle} style={{ textAlign: 'center' }}>
            Project Status
          </Typography>
          <ProjectStageCardSmall projectData={project} className={classes.cardContent}/>
        </Grid>
        <Grid item xs={4}>
          <Typography className={classes.cardTitle} style={{ textAlign: 'center' }}>
            Square Ftg
          </Typography>
          <div style={{ display: 'flex', flexDirection: 'column', gap: '0px' }}>
            <SquareFootageCard projectData={project} className={classes.cardContent} />
          </div>
        </Grid>

        <Grid item xs={4}>
          <Typography className={classes.cardTitle} style={{ textAlign: 'center' }}>
            Start Date
          </Typography>
          <StartDateCard projectData={project} className={classes.cardContent} />
        </Grid>
        <Grid item xs={4}>
          <Typography variant="subtitle1" className={classes.cardTitle} style={{ textAlign: 'center' }}>
            Finish
          </Typography>
          <ProjectedEndDateCard projectData={project} className={classes.cardContent} />
        </Grid>
        <Grid item xs={4}>
          <Typography variant="subtitle1" className={classes.cardTitle} style={{ textAlign: 'center' }}>
            Duration
          </Typography>
          <ProjectDurationCard projectData={project} className={classes.cardContent}/>
        </Grid>
      </Grid>
    </CardContent>
  );
};

export default PortfolioMasterProjectInfoCard;
