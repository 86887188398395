import React, { useState } from "react";
import { Autocomplete, TextField } from "@mui/material";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  textField: {
    "& .MuiInputBase-input": {
      color: "white",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "white",
      },
      "&:hover fieldset": {
        borderColor: "white",
      },
      "&.Mui-focused fieldset": {
        borderColor: "white",
      },
    },
    "& .MuiInputLabel-root": {
      color: "white",
      "&.Mui-focused": {
        color: "white",
      },
    },
    "& .MuiAutocomplete-endAdornment .MuiSvgIcon-root": {
      color: "white",
    },
  },
  chip: {
    color: "white",
    backgroundColor: "transparent",
    border: "1px solid white",
    borderRadius: "5px",
    width: "auto",
  },
  chipDeleteIcon: {
    color: "white",
  },
}));

const PortfolioCompanySelect = ({
  selectedCompany = [],
  options = [],
  onChange,
}) => {
  const classes = useStyles();
  // State for input value (used for the search box in Autocomplete)
  const [inputValue, setInputValue] = useState("");

  const handleClearSelection = () => {
    onChange([]); // Clear the selection
    setInputValue(""); // Clear the search input
  };

  // Clean and deduplicate options
  const cleanOptions = Array.isArray(options)
  ? options.filter((option) => option && option.company)
  : [];

  return (
    <div className="select-dropdown-container">
      <Autocomplete
        multiple
        value={selectedCompany}
        onChange={(event, newValue) => onChange(newValue)} 
        options={cleanOptions} // Use cleaned options
        getOptionLabel={(option) => option.company || ""} // Expect options to be strings
        renderInput={(params) => (
          <TextField
            {...params}
            label="Company"
            variant="outlined"
            fullWidth
            className={`${classes.textField} selector-font-control`}
          />
        )}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        noOptionsText="No options available" // Display this when options are empty
        sx={{
          "& .MuiChip-root": {
            color: "white",
            backgroundColor: "transparent",
            border: "1px solid white",
            borderRadius: "5px",
            width: "auto",
          },
          "& .MuiChip-deleteIcon": {
            color: "white",
          },
        }}
      />
    </div>
  );
};

export default PortfolioCompanySelect;
