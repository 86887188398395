import React from "react";
import {
	FormControl,
	InputLabel,
	Select,
	MenuItem,
	FormControlLabel,
	Checkbox,
	TextField,
	Grid,
} from "@mui/material";

const BVTSettingsFields = ({ bvtFields, setBvtFields }) => {
	const handleFieldChange = (event) => {
		const { name, value, type, checked } = event.target;
		const newValue = type === "checkbox" ? checked : value;

		setBvtFields((prevFields) => ({
			...prevFields,
			[name]: newValue,
		}));
	};

	const blockedFields = new Set([
		"job_has_deferred_tax",
		"neg_insurance_percent",
		"neg_fee_percent",
		"shared_savings",
		"shared_savings_split_percent_to_arco",
		"max_shared_savings_amt",
	]);

	const isFieldDisabled = (fieldName) => {
		return (
			bvtFields.contract_structure === "Lump Sum" &&
			blockedFields.has(fieldName)
		);
	};

	const fields = [
		{
			label: "Contract Structure",
			name: "contract_structure",
			component: (
				<FormControl variant="outlined" fullWidth>
					<InputLabel>Contract Structure</InputLabel>
					<Select
						name="contract_structure"
						value={bvtFields.contract_structure || "Missing"}
						onChange={handleFieldChange}
						label="Contract Structure"
					>
						<MenuItem value="GMP">GMP</MenuItem>
						<MenuItem value="Lump Sum">Lump Sum</MenuItem>
					</Select>
				</FormControl>
			),
		},
		{
			label: "Job Has Deferred Tax",
			name: "job_has_deferred_tax",
			component: (
				<FormControlLabel
					control={
						<Checkbox
							checked={bvtFields.job_has_deferred_tax || false}
							onChange={handleFieldChange}
							name="job_has_deferred_tax"
							color="primary"
							disabled={isFieldDisabled("job_has_deferred_tax")}
						/>
					}
					label="Job Has Deferred Tax"
				/>
			),
		},
		{
			label: "Negotiated Insurance Percent",
			name: "neg_insurance_percent",
			component: (
				<TextField
					label="Negotiated Insurance Percent"
					variant="outlined"
					name="neg_insurance_percent"
					value={bvtFields.neg_insurance_percent || ""}
					onChange={handleFieldChange}
					type="number"
					InputProps={{ inputProps: { min: -100, max: 100 } }}
					disabled={isFieldDisabled("neg_insurance_percent")}
					fullWidth
				/>
			),
		},
		{
			label: "Shared Savings",
			name: "shared_savings",
			component: (
				<FormControlLabel
					control={
						<Checkbox
							checked={bvtFields.shared_savings || false}
							onChange={handleFieldChange}
							name="shared_savings"
							color="primary"
							disabled={isFieldDisabled("shared_savings")}
						/>
					}
					label="Shared Savings"
				/>
			),
		},
		{
			label: "Negotiated Fee Percent",
			name: "neg_fee_percent",
			component: (
				<TextField
					label="Negotiated Fee Percent"
					variant="outlined"
					name="neg_fee_percent"
					value={bvtFields.neg_fee_percent || ""}
					onChange={handleFieldChange}
					type="number"
					InputProps={{ inputProps: { min: -100, max: 100 } }}
					disabled={isFieldDisabled("neg_fee_percent")}
					fullWidth
				/>
			),
		},
		{
			label: "Shared Savings Split Percent to ARCO",
			name: "shared_savings_split_percent_to_arco",
			component: (
				<TextField
					label="Shared Savings Split Percent to ARCO"
					variant="outlined"
					name="shared_savings_split_percent_to_arco"
					value={bvtFields.shared_savings_split_percent_to_arco || ""}
					onChange={handleFieldChange}
					type="number"
					InputProps={{ inputProps: { min: 0, max: 100 } }}
					disabled={isFieldDisabled("shared_savings_split_percent_to_arco")}
					fullWidth
				/>
			),
		},
		{
			label: "Max Shared Savings Amount",
			name: "max_shared_savings_amt",
			component: (
				<TextField
					label="Max Shared Savings Amount"
					variant="outlined"
					name="max_shared_savings_amt"
					value={bvtFields.max_shared_savings_amt || ""}
					onChange={handleFieldChange}
					type="number"
					disabled={isFieldDisabled("max_shared_savings_amt")}
					fullWidth
				/>
			),
		},
	];

	return (
		<div
			style={{
				display: "flex",
				flexDirection: "column",
				gap: "16px",
				margin: "20px",
				justifyContent: "center",
				alignItems: "center",
			}}
		>
			<Grid container spacing={2}>
				{fields.map((field, index) => (
					<Grid item xs={12} md={6} key={index}>
						{field.component}
					</Grid>
				))}
			</Grid>
		</div>
	);
};

export default BVTSettingsFields;