import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Autocomplete, TextField } from '@mui/material';
import { setSelectedProjectId, setSelectedProject } from '../../../../store/slices/projectSlice';


const useReduxSelectors = () => {
	const projects = useSelector((state) => state.project.projects);
	const selectedProject = useSelector((state) => state.project.selectedProject);
	const selectedProjectId = useSelector((state) => state.project.selectedProjectId);
	return { projects,selectedProject, selectedProjectId };
};

const ProjectSelect = () => {
	const { projects,selectedProject, selectedProjectId } = useReduxSelectors();
	const [displayedProjects, setDisplayedProjects] = useState([]);
	const dispatch = useDispatch();

	useEffect(() => {
		const sortedProjects = [...projects].sort((a, b) => {
			const isANumber = /^\d/.test(a.name);
			const isBNumber = /^\d/.test(b.name);
			if (isANumber && !isBNumber) return 1;
			if (!isANumber && isBNumber) return -1;
			return a.name.localeCompare(b.name);
		});
		setDisplayedProjects(sortedProjects);
	}, [projects]);

	const handleChange = (event, newValue) => {
		if (newValue) {
			dispatch(setSelectedProjectId(newValue.id));
			dispatch(setSelectedProject(newValue));
		}
	};

	return (
		<div className="select-dropdown-container" style={{ width: '250px' }}>
			<Autocomplete
				value={selectedProject ? selectedProject : null}
				onChange={handleChange}
				options={displayedProjects}
				getOptionLabel={(option) => `${option.project_number} - ${option.name}`}
				renderInput={(params) => (
					<TextField
						{...params}
						label="Select Project"
						variant="outlined"
						fullWidth
						className="selector-font-control"
						sx={{
							'& .MuiInputBase-input': {
								color: 'white',
							},
							'& .MuiOutlinedInput-root': {
								'& fieldset': {
									borderColor: 'white',
								},
								'&:hover fieldset': {
									borderColor: 'white',
								},
								'&.Mui-focused fieldset': {
									borderColor: 'white',
								},
							},
							'& .MuiInputLabel-root': {
								color: 'white',
								'&.Mui-focused': {
									color: 'white',
								},
							},
							'& .MuiAutocomplete-endAdornment .MuiSvgIcon-root': {
								color: 'white',
							},
						}}
					/>
				)}
				isOptionEqualToValue={(option, value) => option.name === value.name}
				noOptionsText="No projects found. Only projects at a stage of 95% and above in Salesforce are included in this tool."
			/>
		</div>
	);
};

export default ProjectSelect;