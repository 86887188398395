import React, {useEffect, useState} from 'react';
import { CardContent, Typography, Grid } from "@material-ui/core";
import ProjectExternalLinks from './ProjectLinksComponent';

const QuickLinks = ({ projectData, selectedProjectId }) => {

    return (
        <CardContent style={{ padding: 0 }}>
          {projectData ? (
            <Grid container justifyContent="center" style={{marginLeft: '20px'}}>
              <Grid item>
                <ProjectExternalLinks
                  selectedProjectId={selectedProjectId}
                  projectData={projectData}
                />
              </Grid>
            </Grid>
          ) : (
            <div style={{ textAlign: 'center' }}>
              <Typography variant="body1">--</Typography>
            </div>
          )}
        </CardContent>
      );
    };
    
    export default QuickLinks;