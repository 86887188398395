import React, {useState} from 'react';
import {CardContent, Grid, Typography, IconButton, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from '@material-ui/core';
import IconInfo from '@mui/icons-material/Info';
import { makeStyles } from '@material-ui/core/styles';
import { formatDollar, formatDollarShort, getColorBasedOnValue, extractNumericValue } from '../utilityFunctions';

// Styles for the component
const useStyles = makeStyles((theme) => ({
  cardTitle: {
    fontSize: '0.85rem',
    color: '#003865',
    textAlign: 'center',
  },
  cardContent: {
    fontSize: '0.90rem',
    textAlign: 'center',
  },
  gridItem: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100px',
    gap: '8px',
  },
  }));
  
  const FinancialSummaryComponent = (projectData) => {
    const classes = useStyles();
    const project = projectData?.projectData || projectData;
    // console.log("Project Info:")
    // console.log(project)
    const baselineContractData = project?.baseline_contract_summary
    const penultimateComparisonData = project?.penultimate_contract_summary;
    const [dialogOpen, setDialogOpen] = useState(false);

    const handleDialogOpen = () => {
      setDialogOpen(true);
    };
    const handleDialogClose = () => {
      setDialogOpen(false);
    };

    // Calculate Profit Value
    const currentProfitValue = (typeof baselineContractData?.current_profit_percentage === 'number' && typeof baselineContractData?.revised_contract_value === 'number')
    ? (baselineContractData.current_profit_percentage / 100) * baselineContractData.revised_contract_value
    : null;

    // Calculate Profit Percentage Change


    // Calc Owner Contract Value Change
    const ownerContractChangeValue = baselineContractData?.revised_contract_value - baselineContractData?.original_contract_value;

    // Contract Data
    const cleanedbaselineContractData = {
      revisedOwnerContract: formatDollar(baselineContractData?.revised_contract_value) || '--',
      profitValue: '$100,000',
      profitPercentage: typeof baselineContractData?.current_profit_percentage === 'number' 
      ? baselineContractData.current_profit_percentage.toFixed(2)
      : '--',
      ownerContractChange: typeof ownerContractChangeValue === 'number' && !isNaN(ownerContractChangeValue)
        ? formatDollarShort(ownerContractChangeValue)
        : '--',
      profitPercentageChange: '+25',
      jtdCosts: typeof baselineContractData?.jtd_costs === 'number'
      ? baselineContractData.jtd_costs : '--',
    };

    const cleanedComparisonData = {
      profitPercentageChangeByMonth: typeof penultimateComparisonData?.current_profit_percentage_change === 'number' ? penultimateComparisonData.current_profit_percentage_change.toFixed(2) : '--',
      amountChangeRevisedContract: typeof penultimateComparisonData?.revised_contract_value_change === 'number' ? penultimateComparisonData.revised_contract_value_change.toFixed(2) : '--',
      costsChangeAmount: typeof penultimateComparisonData?.total_estimated_costs_change === 'number' ? penultimateComparisonData.total_estimated_costs_change : '--',

    }

    const amountChangeColor = getColorBasedOnValue(
      formatDollar(cleanedComparisonData.amountChangeRevisedContract)
    );
    const costsChangeColor = getColorBasedOnValue(
      formatDollar(cleanedComparisonData.costsChangeAmount)
    );
    const profitPercentageChangeColor = getColorBasedOnValue(
      `${cleanedComparisonData.profitPercentageChangeByMonth}%`
    );

    const amountChangeValue = extractNumericValue(
      cleanedComparisonData.amountChangeRevisedContract
    );
    const profitPercentageChangeValue = extractNumericValue(
      cleanedComparisonData.profitPercentageChangeByMonth
    );
  
    return (
      <CardContent>
{/*    
      <IconButton
        style={{ position: 'absolute', top: -10, right: -10 }}
        onClick={handleDialogOpen}
      >
        <IconInfo />
      </IconButton>
  */}
      <Grid container spacing={1} style={{ width: '100%', justifyContent: 'center' }}>
        {/* Owner Contract */}
        <Grid item xs={6} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <Typography className={classes.cardTitle}>Owner Contract</Typography>
          <Typography className={classes.cardContent}>{cleanedbaselineContractData.revisedOwnerContract}</Typography>
          {amountChangeValue !== null && amountChangeValue !== 0 && (
            <Typography
              style={{
                fontSize: 'small',
                color: amountChangeColor,
                marginTop: '-5px',
              }}
            >
              ({formatDollar(cleanedComparisonData.amountChangeRevisedContract)})
            </Typography>
          )}
        </Grid>
    
        {/* Profit Value */}
        <Grid item xs={6} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <Typography className={classes.cardTitle}>Profit Value</Typography>
          <Typography className={classes.cardContent}>{formatDollar(currentProfitValue)}</Typography>
        </Grid>
    
        {/* JTD Costs */}
        <Grid item xs={6} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <Typography className={classes.cardTitle}>JTD Costs</Typography>
          <Typography className={classes.cardContent}>{formatDollar(cleanedbaselineContractData.jtdCosts)}</Typography>
          {amountChangeValue !== null && amountChangeValue !== 0 && (
            <Typography
              style={{
                fontSize: 'small',
                color: amountChangeColor,
                marginTop: '-5px',
              }}
            >
              ({formatDollar(cleanedComparisonData.costsChangeAmount)})
            </Typography>
          )}
        </Grid>
    
        {/* Current Profit % */}
        <Grid item xs={6} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <Typography className={classes.cardTitle}>Current Profit %</Typography>
          <Typography className={classes.cardContent}>{cleanedbaselineContractData.profitPercentage}%</Typography>
          {profitPercentageChangeValue !== null && profitPercentageChangeValue !== 0 && (
            <Typography
              style={{
                fontSize: 'small',
                color: profitPercentageChangeColor,
                marginTop: '-5px',
              }}
            >
              ({cleanedComparisonData.profitPercentageChangeByMonth}%)
            </Typography>
          )}
        </Grid>
      </Grid>
    </CardContent>
    
    
    );
  };
  
  export default FinancialSummaryComponent;