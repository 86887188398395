

// API Functions
export const getHeaders = (csrfToken, apiHeader, apiKey) => { 
	let header = new Headers({
		'Content-Type': 'application/json',
		'X-CSRFToken': csrfToken,
		'Auth': apiHeader,
		'api-key': apiKey,
	});
	return header;
}

export const getSortFields = () => ([
    { label: 'Substantial Completion ↑', value: 'substantial_completion_date' },
    { label: 'Project Number ↑', value: 'project_number' },
    { label: 'Project Name ↑', value: 'project_name' },
    { label: 'Profit Margin ↑', value: 'profit_margin' },
    { label: 'Profit Margin ↓', value: '-profit_margin' },
    { label: 'Contract Amount ↑', value: 'contract_amount' },
    { label: 'Contract Amount ↓', value: '-contract_amount' },
])

export const getRequiredRoles = () => ([
	"Project Manager",
	"Superintendent",
	"Project Coordinator",
	"Project Executive",
	"General Superintendent",
	"Project Accountant",
])

export const toastStyle = () => ({
    duration: 3000,
    position: 'top-right',
    className: 'titillium-web',
})