import {useState, useEffect} from 'react';
import { Grid, Card, CardContent, Typography, Tooltip } from '@material-ui/core';

import PercentComplete from './PercentCompleteComponent';
import ProfitPercentage from './CurrentProfitComponent';

import CurrentContractAmount from './CurrentContractAmountComponent';
import OGContractAmount from './OriginalContractAmountComponent';
import TotalEstimatedCosts from './TotalEstimatedCostsComponent';
import JTDCosts from './JTDCostsComponent';
import ContractChangePercentage from './ContractChangePercentageComponent';
import ProfitChangePercentage from './ProfitChangePercentageComponent';
import { borderRadius } from '@mui/system';


const MasterContractInfoCard = ({ budgetData }) => {

    // useEffect to trigger re-render when delayed budgetData is received
        // API currently lagging projectData endpoint by ~20sec
    const [updatedBudgetData, setUpdatedBudgetData] = useState(null);

    useEffect(() => {
        if (budgetData) {
            setUpdatedBudgetData(budgetData);
        }
    }, [budgetData]);

    return (
        <CardContent style={{marginBottom: '-30px', marginTop: '-15px', marginLeft: '-20px', marginRight: '-20px'}}>
                <Grid container spacing={2} style={{ width: '100%', justifyContent: 'center' }}>
                    <Grid item xs={4}>
                        <Typography variant="subtitle1" className="card-title" style={{ textAlign: 'center' }}>Current Contract</Typography>
                        <span className='ppsf-container'>
                            <CurrentContractAmount budgetData={budgetData} />
                            <Tooltip
                            title={
                                <div className="warning-icon-tooltip-content">
                                    <Typography style={{fontSize: '1rem', padding: '5px', borderRadius: '3px', fontFamily: 'Titillium Web, sans-serif'}}>This number reflects total contract change from baseline.</Typography>
                                </div>
                            }
                        >
                            <span>
                                <ContractChangePercentage budgetData={budgetData} />
                            </span>
                        </Tooltip>
                        </span>
                        
                    </Grid>
                <Grid item xs={4}>
                    <Typography variant="subtitle1" className="card-title" style={{ textAlign: 'center' }}>Current Profit %</Typography>
                    <span className='ppsf-container'>
                        <ProfitPercentage budgetData={budgetData} />
                        <Tooltip
                            title={
                                <div className="warning-icon-tooltip-content">
                                    <Typography style={{fontSize: '1rem', padding: '5px', borderRadius: '3px', fontFamily: 'Titillium Web, sans-serif'}}>Change in profit amount over the past month. Comparing the latest 2 budget snapshots.</Typography>
                                </div>
                            }
                        >
                        <span>
                            <ProfitChangePercentage budgetData={budgetData} />
                        </span>
                        </Tooltip>
                    </span>
                </Grid>
                <Grid item xs={4}>
                    <Typography variant="subtitle1" className="card-title" style={{ textAlign: 'center' }}>Percent Complete</Typography>
                    <PercentComplete budgetData={budgetData} />
                </Grid>
                <Grid item xs={4}>
                    <Typography variant="subtitle1" className="card-title" style={{ textAlign: 'center' }}>Original Contract</Typography>
                    <OGContractAmount budgetData={budgetData} />
                </Grid>
                <Grid item xs={4}>
                    <Typography variant="subtitle1" className="card-title" style={{ textAlign: 'center' }}>Estimated Costs</Typography>
                    <TotalEstimatedCosts budgetData={budgetData} />
                </Grid>
                <Grid item xs={4}>
                    <Typography variant="subtitle1" className="card-title" style={{ textAlign: 'center' }}>JTD Costs</Typography>
                    <JTDCosts budgetData={budgetData} />
                </Grid>
            
         </Grid>
        </CardContent>
    )
}


export default MasterContractInfoCard;