import React from "react";

import { Typography } from "@material-ui/core";
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

const ProfitChangePercentage = ({ budgetData }) => {
    if (!budgetData || budgetData.length === 0 || budgetData?.baseline_contract_summary?.profit_percentage_change === 0) {
        return <Typography style={{textAlign: 'center'}} className="ppsf-mini">--</Typography>
    }

    const currentProfitPercentChange = budgetData?.baseline_contract_summary?.profit_percentage_change || 0;

    const isPositive = currentProfitPercentChange >= 0;

    let color = currentProfitPercentChange >= 0 ? 'green' : 'red';
    const ArrowIcon = isPositive ? ArrowDropUpIcon : ArrowDropDownIcon;


    return (
        <Typography style={{ color: color, textAlign: 'center' }} className='ppsf-mini'>
            {currentProfitPercentChange.toFixed(2)}%
            <ArrowIcon fontSize="small" style={{ marginLeft: '2px', verticalAlign: 'top' }} />
        </Typography>
    );

}

export default ProfitChangePercentage;