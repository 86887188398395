import React from "react";

import { Card, CardContent, Typography } from "@material-ui/core";

const formatNumber = (number) => {
    return new Intl.NumberFormat('en-US').format(number);
};

const SquareFootageCard = ({ projectData, className }) => {
    const squareFootage = projectData?.project_data?.square_feet || projectData?.square_feet || '--'


return (
    <div>
        {projectData && Object.keys(projectData).length > 0 ? (
            <Typography className={className} style={{ textAlign:'center'}}>
                {formatNumber(squareFootage) + " SF" || '--'}</Typography>
        ) : (
            <Typography className={className} style={{textAlign: 'center'}}>--</Typography>
        )}
    </div>
    );
}

export default SquareFootageCard;