import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import store from '../src/store/store';

import { createBrowserHistory } from 'history';

import { MuiThemeProvider, createTheme } from '@material-ui/core/styles';
import { blue } from '@material-ui/core/colors';

import App from './containers/app/AppContainer';
import reportWebVitals from './reportWebVitals';

import './style.scss';
import { AuthProvider } from './auth/useAuth';

const mountNode = document.getElementById('root');
export const history = createBrowserHistory();

require('dotenv').config();

const theme = createTheme({
  typography: {
      fontFamily: 'Roboto',
      fontWeight: '200 !important',
      // useNextVariants: true,
  },
  spacing: 8,
  palette: {
      primary: blue,
  },
  overrides: {
      MuiTooltip: {
          tooltip: {
              backgroundColor: 'black',
          },
          arrow: {
              color: 'black',
          },
      },
  },
});


// Used to make the React and Store available to fiddle with in Browser Console
// To be removed in Production Mode
// window.React = React;


// const onRedirectCallback = (appState) => {
//   history.push(
//     appState && appState.returnTo ? appState.returnTo : window.location.pathname
//   );
// };

const onRedirectCallback = (appState) => {
  // Use the router's history module to replace the url
  history.replace(appState?.returnTo || window.location.pathname);
};


// const authConfig = getConfig();
// const providerConfig = {
//   domain: authConfig.domain,
//   clientId: authConfig.clientId,
//   ...(authConfig.audience ? { audience: authConfig.audience } : null),
//   redirectUri: window.location.origin,
//   onRedirectCallback: onRedirectCallback,
// };


ReactDOM.render(
  // <React.StrictMode history={history}>
  <Suspense fallback={<div>Error! Please refresh the page</div>}>
    <MuiThemeProvider theme={theme}>
      <AuthProvider>
      <Provider store={store}>
        <App />
      </Provider>
      </AuthProvider>
    </MuiThemeProvider>
  </Suspense>,
  mountNode
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
