import { createSlice } from '@reduxjs/toolkit';

// Define Values for Initial State (empty & null values)
const initialState = {
  projectData: {},
  budgetData: [],
  teamData: [],
  selectedProjectId: null,
  selectedProject: null,
  invoiceData: [],
  settingsData: [],
  revenueSpreadData: {},
  projects: [],
  companies: [],
  selectedCompany: null,
  overUnderData: [],
};

// Create Project Slice
const projectSlice = createSlice({
  name: 'project',
  initialState,
  reducers: {
    // Setters for projects and companies
    setProjects(state, { payload }) {
        state.projects = payload;
    },
    setCompanies(state, { payload }) {
        state.companies = payload;
    },
    setSelectedCompany(state, {payload}) {
        state.selectedCompany = payload;
    },
    // Setter for projectData
    setProjectData(state, { payload }) {
      state.projectData = payload;
    },
    // Setter for budgetData
    setBudgetData(state, { payload }) {
      state.budgetData = payload;
    },
    // Setter for teamData
    setTeamData(state, { payload }) {
      state.teamData = payload;
    },
    // Setter for selectedProjectId
    setSelectedProjectId(state, { payload }) {
      state.selectedProjectId = payload;
    },
    // Setter for revenueSpreadData
    setRevenueSpreadData(state, { payload }) {
      state.revenueSpreadData = payload;
    },
    // Setter for selectedProject
    setSelectedProject(state, { payload }) {
      state.selectedProject = payload;
    },
    // Setter for invoiceData
    setInvoiceData(state, { payload }) {
      state.invoiceData = payload;
    },
    // Setter for settingsData
    setSettingsData(state, { payload }) {
      state.settingsData = payload;
    },
    // Setter for OverUnderData
    setOverUnderData(state, { payload }) {
      state.setOverUnderData = payload;
    },
    // Reset all state to initial values
    resetProjectState() {
      return initialState;
    },
  },
});

// Export the actions (setters) to be used in our dashboard
export const {
  setProjectData,
  setBudgetData,
  setTeamData,
  setSelectedProjectId,
  setRevenueSpreadData,
  resetProjectState,
  setSelectedProject,
  setInvoiceData,
  setSettingsData,
  setProjects,
  setCompanies,
  setSelectedCompany,
  setOverUnderData
} = projectSlice.actions;

// Export the reducer for the Redux store
export default projectSlice.reducer;
