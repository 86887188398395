import React from 'react';
import { Tooltip, Button } from '@mui/material';
import WarningIcon from '@mui/icons-material/Warning';

const TooltipButton = ({ title, buttonText, onClick, iconColor }) => (
    <Tooltip
        title={
            <div className='warning-icon-tooltip-content'>
                <p>{title}</p>
                <Button
                    variant="contained"
                    className='smaller-procore-button'
                    size="small"
                    onClick={onClick}
                    style={{ marginTop: '8px', textAlign: 'center' }}
                >
                    {buttonText}
                </Button>
            </div>
        }
        componentsProps={{
            tooltip: {
                sx: {
                    fontSize: '1rem',
                    padding: '10px',
                },
            },
        }}
    >
        <WarningIcon style={{ color: iconColor, marginRight: '10px', verticalAlign: 'middle' }} />
    </Tooltip>
);

export default TooltipButton;