import React from "react";

import { Card, CardContent, Typography } from "@material-ui/core";

const formatDate = (dateString) => {
    if (dateString) {
        const date = new Date(dateString);
        const options = { year: 'numeric', month: 'numeric', day: 'numeric' };
        return date.toLocaleDateString(undefined, options);
    }
};

const ProjectedEndDateCard = ({ projectData, className }) => {
    const compDate = projectData.project_data?.completion_date || projectData?.completion_date

return (
    <div>
        {projectData ? (
            <Typography className={className} style={{ textAlign:'center'}}>{formatDate(compDate) || '--'}</Typography>
        ) : (
            <Typography className={className} variant="body1">N/A - Select a Project</Typography>
        )}
    </div>
    );
}

export default ProjectedEndDateCard;