import React from 'react';

import {Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper} from '@material-ui/core';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import { CardContent } from '@mui/material';

const useStyles = makeStyles({
    // table: {
    //     minWidth: 500,
    // }
});

const ViewpointExpenseTracker = ({viewpointUrl, isModal}) => {
    const classes = useStyles();

    return (
        <CardContent >
            {viewpointUrl ? (
            <div style={{ width: '100%', height: '90vh' }}>
                <iframe
                    src={viewpointUrl}
                    style={{ width: '100%', height: '100%', border: 'none' }}
                    title="Embedded Content"
                    allowFullScreen
                />
            </div>                        
            ): (
                <Typography variant="h6" color="textSecondary" align="center">
                    Our team is in the process of getting access to Viewpoint data. This tracker will be added as soon.
                </Typography>
                // <Typography variant="h6" color="textSecondary" align="center">
                //     The Viewpoint Expense Tracker URL is broken. Please reach out to innovation@arcomurray.com.
                // </Typography>
            )}
        </CardContent>
    );
};

export default ViewpointExpenseTracker;
