import { configureStore } from '@reduxjs/toolkit';
import projectReducer from './slices/projectSlice';
import globalReducer from './slices/globalSlice';
import portfolioReducer from './slices/portfolioSlice';

const store = configureStore({
    reducer: {
        project: projectReducer,
        global: globalReducer,
        portfolio: portfolioReducer,
    },
});

export default store;
