import { useState, useEffect } from "react";

const MilestoneProgressBar = ({ projectData }) => {
  const [scheduleProgress, setScheduleProgress] = useState("--");

  const parseDate = (dateString) => {
    const parsedDate = new Date(dateString);
    return isNaN(parsedDate) ? null : parsedDate;
  };

  function calculateMilestonePercentages(projectData) {
    if (!projectData || !projectData.project_data) {
      return [];
    }

    const {
      actual_start_date,
      completion_date,
      projected_finish_date,
    } = projectData.project_data;

    const actualStartDate = parseDate(actual_start_date);
    const completionDate = parseDate(completion_date);
    const projectedFinishDate = parseDate(projected_finish_date);

    if (!actualStartDate || !projectedFinishDate) {
      return [];
    }

    const totalDurationInDays = (projectedFinishDate - actualStartDate) / (1000 * 60 * 60 * 24);

    if (totalDurationInDays <= 0) {
      return [];
    }

    // Calculate days for each milestone
    const groundbreakingDays = 0; // NOTE: Groundbreaking is at start date until provided in API
    const subsCompletionDays = (completionDate - actualStartDate) / (1000 * 60 * 60 * 24);

    const clampPercentage = (value) => Math.max(0, Math.min(100, value));

    return [
      {
        label: "Start",
        percentage: 0, 
        date: actual_start_date || "N/A",
      },
      {
        label: "Groundbreaking",
        percentage: clampPercentage((groundbreakingDays / totalDurationInDays) * 100),
        date: actual_start_date || "N/A",
      },
      {
        label: "Subs. Completion",
        percentage: clampPercentage((subsCompletionDays / totalDurationInDays) * 100),
        date: completion_date || "N/A",
      },
      {
        label: "Finish",
        percentage: 100,
        date: projected_finish_date || "N/A",
      },
    ];
  }

  useEffect(() => {
    if (
      !projectData ||
      !projectData.project_data ||
      !projectData.project_data.actual_start_date ||
      !projectData.project_data.projected_finish_date
    ) {
      setScheduleProgress("--");
      return;
    }

    const calculateScheduleProgress = () => {
      const startDate = parseDate(projectData.project_data.actual_start_date);
      const projectedFinishDate = parseDate(projectData.project_data.projected_finish_date);
      const currentDate = new Date();

      if (currentDate > projectedFinishDate) return 100; 
      if (currentDate < startDate) return 0; 

      const totalDuration = projectedFinishDate - startDate;
      const elapsedDuration = currentDate - startDate;

      return Math.max(0, Math.min(100, (elapsedDuration / totalDuration) * 100));
    };

    setScheduleProgress(calculateScheduleProgress());
  }, [projectData]);

  let progress = scheduleProgress === "--" ? 0 : scheduleProgress;
  let milestones = calculateMilestonePercentages(projectData);

  const getMilestoneColor = (milestonePercentage) => {
    return progress >= milestonePercentage ? "#083d61" : "#e0e0e0";
  };

  if (!projectData || !projectData.project_data) {
    return '--'
  }

  return (
    <div className="progress-chart-container titillium-web" style={{ width: "65%" }}>
      <div
        style={{
          width: "100%",
          position: "relative",
          paddingTop: "40px",
          paddingBottom: "0px",
        }}
      >
        {/* Progress Bar */}
        <div
          style={{
            width: "100%",
            height: "35px",
            backgroundColor: "#f3f3f3",
            borderRadius: "10px",
            boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
            overflow: "hidden",
            position: "relative",
          }}
        >
          <div
            style={{
              width: `${progress}%`,
              height: "100%",
              background: "linear-gradient(90deg,  #08304b, #107ac2, #107ac2)",
              boxShadow: "0 0 10px rgba(84, 190, 243, 0.5)",
              transition: "width 2s ease",
            }}
          />

          {/* Progress Label */}
          <div
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              color: progress >= 50 ? "#fff" : "#083d61",
              fontWeight: "bold",
              fontSize: "12px",
              textShadow: progress >= 50 ? "0 1px 2px rgba(0, 0, 0, 0.2)" : "none",
              whiteSpace: "nowrap",
            }}
          >
            {scheduleProgress === "--" ? "--" : `${Math.round(progress)}%`}
          </div>
        </div>

        {/* Milestones */}
        {milestones.map((milestone, index) => {
          const isTopMilestone = index === 1 || index === 2; 
          const isBottomMilestone = index === 0 || index === 3; 

          return (
            <div
              key={milestone.label}
              style={{
                position: "absolute",
                left: `${milestone.percentage}%`,
                transform: "translateX(-50%)",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                ...(isTopMilestone ? { top: "-15px" } : {}),
                ...(isBottomMilestone ? { top: "calc(100% + 0px)" } : {}),
              }}
            >
              <div
                style={{
                  width: "2px",
                  backgroundColor: getMilestoneColor(milestone.percentage),
                  height: isTopMilestone ? "0px" : "18px",
                  ...(isBottomMilestone
                    ? { marginBottom: "0px" }
                    : { marginBottom: isTopMilestone ? "-5px" : "0" }),
                  zIndex: 1,
                }}
              />
              <div
                style={{
                  fontSize: "12px",
                  color: getMilestoneColor(milestone.percentage),
                  textAlign: "center",
                  whiteSpace: "nowrap",
                  zIndex: 2,
                  position: "relative",
                  backgroundColor: "white",
                  padding: "0 5px",
                }}
              >
                {milestone.label}
                <br />
                {milestone.date}
              </div>
              {isTopMilestone && (
                <div
                  style={{
                    width: "2px",
                    backgroundColor: getMilestoneColor(milestone.percentage),
                    height: "18px",
                    marginTop: "5px",
                    zIndex: 1,
                  }}
                />
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default MilestoneProgressBar;
