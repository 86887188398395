import React from "react";

import { Card, CardContent, Typography } from "@material-ui/core";
import { formatPercentage } from "../utilityFunctions";
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

const ContractChangePercentage = ({ budgetData }) => {
    if (
        !budgetData ||
        !budgetData.current_contract_summary ||
        budgetData.current_contract_summary.revised_contract_value == null ||
        budgetData.current_contract_summary.original_contract_value == null) {
            return <Typography style={{textAlign: 'center'}} className="ppsf-mini"></Typography>
    }

    let currentValue = budgetData?.current_contract_summary?.revised_contract_value || 0;
    let baselineValue = budgetData?.baseline_contract_summary?.original_contract_value || 0;

    // Check for division by zero to prevent Infinity's
    if (!baselineValue || baselineValue === 0) {
        return <Typography style={{ textAlign: 'center' }} className="ppsf-mini">--</Typography>;
    }

    // Calculating Percent Change
    const percentChange = ((currentValue - baselineValue) / baselineValue) * 100

    // Flag to control arrow color
    const isPositive = percentChange >= 0;

    // Assign 'n define arrow color
    let color = percentChange >= 0 ? 'green' : 'red';
    const ArrowIcon = isPositive ? ArrowDropUpIcon : ArrowDropDownIcon;


    return (
        <Typography style={{ color: color, textAlign: 'center' }} className='ppsf-mini'>
            {percentChange.toFixed(2)}%
            <ArrowIcon fontSize="small" style={{ marginLeft: '2px', verticalAlign: 'top' }} />
        </Typography>
    );

}

export default ContractChangePercentage;