import React from "react";
import procoreLogo from '../../static/procore_logo.png';
import sfdcLogo from '../../static/sfdc-logo.png';
import hubDash from '../../static/hub_logo_arcoblue.png';
import scribeLogo from '../../static/scribeLogo2.svg'
import GradeIcon from '@mui/icons-material/Grade';
import EmailIcon from '@material-ui/icons/Email';

import { Link, Tooltip } from "@mui/material";
import LanguageIcon from '@mui/icons-material/Language';
import toast from "react-hot-toast";

// Dev Note: Passing links with noreferrer to help with security. May need to get rid of this if we want ever want analytics on these.

// TODO: Verify Arco_id and hub

const ProjectExternalLinks = ({projectData, selectedProjectId}) => {

    if (!projectData || !projectData.project_data || (!projectData.project_data.procore_id && !projectData.project_data.sfdc_id && !projectData.project_data.arco_id && !selectedProjectId)) {
        return null;
    }

    const procoreURL = `https://app.procore.com/projects/${projectData.project_data.procore_id}/budgeting`
    const salesforceURL = `https://arcomurray.lightning.force.com/lightning/r/Opportunity/${projectData.project_data.sfdc_id}/view`
    const hubURL = `https://hub.arcomurray.com/project/${selectedProjectId}/`
    const scribeURL = `https://https://scribehow.com/shared/Project_Health_Dashboard_PHD__QIjB8iH3Tsq8igtQYw0UgA.com/signin`
    const subRatingsURL = `https://hub.arcomurray.com/sub-ratings/${selectedProjectId}/`
    const summaryEmailURL = `#`

    const handleEmailClick = (e) => {
        e.preventDefault(); 
        toast.success("Hold tight! This feature will be ready by February 20th.", {
            className: "titillium-web",
        });
    };

    return (
        <div className='project-icons-container control-panel' style={{verticalAlign: 'middle'}}>
            <Tooltip title="Open Project Budget in Procore" arrow>
                <Link id="link-1" href={procoreURL} target="_blank" rel="noopener noreferrer" className="control-button">
                        <img src={procoreLogo} className="project-link-icon" alt="Procore" />
                </Link>
            </Tooltip>
            <Tooltip title="Open Project in Salesforce" arrow>
                <Link id="link-2" href={salesforceURL} target="_blank" rel="noopener noreferrer" variant="body2" className="control-button">
                    <img src={sfdcLogo} className="project-link-icon" alt="Salesforce" title="Open in Salesforce"/>
                </Link>
            </Tooltip>
            <Tooltip title="Open Project in ARCO Hub" arrow>
                <Link id="link-3" href={hubURL} target="_blank" rel="noopener noreferrer" variant="body2" className="control-button">
                    <img src={hubDash} className="project-link-icon" alt="Hub Dash" title="Open Hub Project Details"/>
                </Link> 
            </Tooltip>
            <Tooltip title="Open Sub Ratings" arrow>
                <Link id="link-4" href={subRatingsURL} target="_blank" rel="noopener noreferrer" variant="body2" className="control-button">
                    <GradeIcon style={{fontSize: 'large', color: '#049be5'}} title="Rate Subs" />
                </Link>
            </Tooltip>
            <Tooltip title="Send Cost Status Summary Email" arrow>
                <Link
                    id="link-5"
                    href={summaryEmailURL}
                    target="_blank"
                    onClick={handleEmailClick}
                    className="control-button"
                >
                    <EmailIcon style={{ fontSize: 'large', color: '#049be5' }} title="Cost Status Summary Email" />
                </Link>
            </Tooltip>
            {/* <Link id="link-5" href={scribeURL} target="_blank" rel="noopener noreferrer" variant="body2" className="control-button">
                <img src={scribeLogo} className="project-link-icon" alt="Scribe" title="Open Scribe for PHD"/>
            </Link> */}
        </div>
    )
}

export default ProjectExternalLinks;