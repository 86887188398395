import React, { useState, useEffect } from 'react';
import { Grid, CardContent, Typography } from '@material-ui/core';
import { formatDollarFull } from '../utilityFunctions';

const RevenueSpreadCard = ({ revenueSpreadData }) => {
    const [data, setData] = useState(revenueSpreadData);

    useEffect(() => {
        setData(revenueSpreadData);
    }, [revenueSpreadData]);

    const today = new Date();
    let year = today.getFullYear();
    let month = today.getMonth() + 1; // Months are 0-indexed, so add 1.
    if (month === 1) {
        year = year - 1;
    }

    // Provide default values to prevent NaN
    const sfdc_total_revenue = data.sfdc_total_revenue || 0;
    const sfdc_off_the_books_revenue = data.sfdc_off_the_books_revenue || 0;
    const sfdc_percent_revenue_this_year = data.sfdc_percent_revenue_this_year || 0;
    const sfdc_percent_revenue_second_year = data.sfdc_percent_revenue_second_year || 0;
    const sfdc_percent_revenue_third_year = data.sfdc_percent_revenue_third_year || 0;

    const unearned_revenue = sfdc_total_revenue - sfdc_off_the_books_revenue;
    const rev_this_year = unearned_revenue * (sfdc_percent_revenue_this_year / 100);
    const rev_second_year = unearned_revenue * (sfdc_percent_revenue_second_year / 100);
    const rev_third_year = unearned_revenue * (sfdc_percent_revenue_third_year / 100);

    return (
        <CardContent style={{ marginBottom: '0px', marginTop: '-15px', marginLeft: '-20px', marginRight: '-20px' }}>
            <Grid container spacing={2} style={{ width: '100%', justifyContent: 'center' }}>
                <Grid item xs={4}>
                    <Typography variant="subtitle1" className="card-title" style={{ textAlign: 'center' }}>Total Revenue</Typography>
                    <Typography style={{textAlign: 'center'}}className="main-key-project-info-body">{formatDollarFull(sfdc_total_revenue) || '--'}</Typography>
                </Grid>
                <Grid item xs={4}>
                    <Typography variant="subtitle1" className="card-title" style={{ textAlign: 'center' }}>Earned in Prior Years</Typography>
                    <Typography style={{textAlign: 'center'}}className="main-key-project-info-body">{formatDollarFull(sfdc_off_the_books_revenue) || '--'}</Typography>
                </Grid>
                <Grid item xs={4}>
                    <Typography variant="subtitle1" className="card-title" style={{ textAlign: 'center', marginBottom: '0px' }}>Unearned Revenue</Typography>
                    <Typography style={{textAlign: 'center'}}className="main-key-project-info-body">{formatDollarFull(unearned_revenue) || '--'}</Typography>
                </Grid>
                <Grid item xs={4}>
                    <Typography variant="subtitle1" className="card-title" style={{ textAlign: 'center' }}>{year} Revenue</Typography>
                    <Typography style={{textAlign: 'center'}}className="main-key-project-info-body">{formatDollarFull(rev_this_year) || '--'}</Typography>
                    <Typography style={{ textAlign: 'center' }} className='ppsf-mini'>{sfdc_percent_revenue_this_year}%</Typography>
                </Grid>
                <Grid item xs={4}>
                    <Typography variant="subtitle1" className="card-title" style={{ textAlign: 'center' }}>{year + 1} Revenue</Typography>
                    <Typography style={{textAlign: 'center'}}className="main-key-project-info-body">{formatDollarFull(rev_second_year) || '--'}</Typography>
                    <Typography style={{ textAlign: 'center' }} className='ppsf-mini'>{sfdc_percent_revenue_second_year}%</Typography>
                </Grid>
                <Grid item xs={4}>
                    <Typography variant="subtitle1" className="card-title" style={{ textAlign: 'center' }}>{year + 2} Revenue</Typography>
                    <Typography style={{textAlign: 'center'}}className="main-key-project-info-body">{formatDollarFull(rev_third_year) || '--'}</Typography>
                    <Typography style={{ textAlign: 'center' }} className='ppsf-mini'>{sfdc_percent_revenue_third_year}%</Typography>
                </Grid>
            </Grid>
        </CardContent>
    );
}

export default RevenueSpreadCard;