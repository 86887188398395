import {useState, useEffect} from "react";
import { useSelector, useDispatch } from 'react-redux';
import bonusToolIcon from '../static/bonus_tool_icon.svg';
import "../static/project-dashboard-styles.css"
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import scribeLogo from '../static/scribeLogo2.svg';
import catGif from '../static/working-hard-cat.gif';
import { Typography, CardContent, Link, Tabs, Tab, Dialog, DialogActions, DialogContent, DialogTitle, Button } from "@mui/material";
import ProjectSelect from "./ProjectViewComponents/ProjectSelectComponent";
import CompanySelect from "./ProjectViewComponents/CompanySelectorComponent"
import PortfolioCompanySelect from "./PortfolioViewComponents/PortfolioCompanySelectorComponent";
import ProjectSettingsModal from "./ProjectViewComponents/ProjectSettingsContainerModal";
import DivisionSelect from "./ProjectViewComponents/DivisionSelectorComponent";
import ProjectStageSelect from "./PortfolioViewComponents/ProjectStageSelectorComponent";
import OpsLeaderSelect from "./PortfolioViewComponents/OpsLeaderSelectorComponent";
import ProjectTypeSelect from "./PortfolioViewComponents/ProjectTypeSelectorComponent";
import AccountSelect from "./PortfolioViewComponents/AccountSelectorComponent";
import FAQModal from "./ProjectViewComponents/FAQComponent";
import BudgetSnapshotSelector from "./ProjectViewComponents/BudgetSnapshotSelectorComponent"
import TutorialModal from "./ProjectViewComponents/TutorialModalComponent"
import {setTab} from "../../../store/slices/globalSlice";
import { setProjectData, setBudgetData, setTeamData, setSelectedProjectId, setSelectedProject, setRevenueSpreadData,setInvoiceData, setSettingsData, resetProjectState, setProjects, setCompanies, setSelectedCompany } from '../../../store/slices/projectSlice';
import { setSelectedDivisions, setSelectedAccounts, set } from "../../../store/slices/portfolioSlice";
import {
	fetchProjectsAndCompanies,
} from '../Containers/api';

const useReduxSelectors = () => {
	const projectData = useSelector((state) => state.project.projectData);
	const authenticated = useSelector((state) => state.global.authenticated);
	const budgetData = useSelector((state) => state.project.budgetData);
	const teamData = useSelector((state) => state.project.teamData);
	const revenueSpreadData = useSelector((state) => state.project.revenueSpreadData);
	const settingsData = useSelector((state) => state.project.settingsData);
	const invoiceData = useSelector((state) => state.project.invoiceData)
	const projects = useSelector((state) => state.project.projects);
	const companies = useSelector((state) => state.project.companies);
	const selectedCompany = useSelector((state) => state.project.selectedCompany);

	const selectedProject = useSelector((state) => state.project.selectedProject);
	const selectedProjectId = useSelector((state) => state.project.selectedProjectId);

	const tab = useSelector((state) => state.global.tab);

	return { projectData, authenticated, budgetData, teamData, revenueSpreadData, settingsData, invoiceData, projects, companies, selectedCompany, selectedProjectId, selectedProject, tab };
};

const HeaderComponent = ({ 
	// Settings Modal
	selectedSnapshots,
	initialSnapshots,

	// Project View

	// Portfolio Filters
	handleCompanySelectChangeForPortfolioView,
	selectedPortfolioCompany,
	setSelectedPortfolioCompany,
	divisionOptions,
	selectedDivisions,
	setSelectedDivisions,
	stageOptions,
	selectedStages,
	setSelectedStages,
	opsLeaderOptions,
	selectedOpsLeaders,
	setSelectedOpsLeaders,
	projectTypeOptions,
	selectedProjectTypes,
	setSelectedProjectTypes,
	accountOptions,
	selectedAccounts,
	setSelectedAccounts,
	}) => {
	// Handling Tab Updates to Redux Store Here
	const dispatch = useDispatch();
	const { projectData, selectedProject, selectedProjectId, tab, settingsData, projects, companies, selectedCompany } = useReduxSelectors();

	const handleTabChange = (newTab) => {
		if (newTab === 'operations') {
			setIsOperationsModalOpen(true);
		} else {
			dispatch(setTab(newTab));
		}
	};

	// Latest Snapshot for Data Updated
	const [mostRecentCreatedAt, setMostRecentCreatedAt] = useState(null);
	const [isOperationsModalOpen, setIsOperationsModalOpen] = useState(false);

	// Pull Project and Companies on First Mount
	useEffect(() => {
		const fetchProjectAndCompanyData = async () => {
			const companyFilterArray = selectedCompany ? [selectedCompany.company] : [];
			try {
				const data = await fetchProjectsAndCompanies(companyFilterArray);        
				dispatch(setProjects(data.projects || []));
				dispatch(setCompanies(data.companies || []));
				if (selectedProjectId) {
					const selectedProject = data.projects.find((project) => project.id === selectedProjectId);
					dispatch(setSelectedProject(selectedProject));
				}
			} catch (error) {
				console.error('Error fetching proj & company data in ProjectView:', error);
			}
		};
		fetchProjectAndCompanyData();
	}, [dispatch,selectedCompany]);

	// Handle company selection change -- Project View
	const handleCompanySelectChangeForProjectView = (event, newValue) => {
		dispatch(setSelectedCompany(newValue));
	};

	useEffect(() => {
		if (selectedSnapshots && selectedSnapshots.length > 0) {
			const mostRecent = selectedSnapshots.reduce((latest, snapshot) => {
				return new Date(snapshot.created_at) > new Date(latest) ? snapshot.created_at : latest;
			}, selectedSnapshots[0].created_at);

			setMostRecentCreatedAt(new Date(mostRecent).toLocaleDateString());
		} else {
			setMostRecentCreatedAt(null);
		}
	}, [selectedSnapshots]);

	// Control Temporary Pop up for Operations Tab
	const handleOperationsTabClick = () => {
		setIsOperationsModalOpen(true); 
	};
	
	return (
		<CardContent className='header-container-card' style={{backgroundColor: '#093d61', borderRadius: '15px', paddingLeft: '10px', paddingRight: '10px', paddingTop: '0px', paddingBottom: '10px', marginBottom: '10px'}}>
			{/* Upper Section Container*/}
			<div
				className='header-lower-section'
				style={{
					display: "flex",
					justifyContent: "center",
					alignItems: "flex-end",
					borderRadius: "5px",
					width: "100%", 
					// maxHeight: '25px',
					paddingBottom: '10px',
					// marginTop: tab === 'portfolio' ? "1.5rem" : "0",
				}}
			>
				<Tabs
					value={tab}
					onChange={(event, newValue) => handleTabChange(newValue)}
					textColor="inherit"
					TabIndicatorProps={{ style: { backgroundColor: "#1b9fff" } }}
					aria-label="view control tabs"
					sx={{
						// border: '0.5px solid white',
						borderBottom: 'none',
						minHeight: '35px',
						borderRadius: '5px 5px 0px 0px',
						backgroundColor: 'rgba(169, 169, 169, 0.01)',
						padding: 0,
						margin: 0,
						'& .MuiTab-root': {
							fontSize: '12px',
							padding: '5px 5px 5px 5px',
							minHeight: '35px',
						}
					}}
				>
					<Tab
						value="portfolio"
						label="PORTFOLIO"
						style={{
							color: tab === 'portfolio' ? "white" : "gray",
							textTransform: "none",
							fontWeight: tab === 'portfolio' ? "bold" : "normal",
						}}
					/>
					<Tab
						value="project"
						label="PROJECT"
						style={{
							color: tab === 'project' ? "white" : "gray",
							textTransform: "none",
							fontWeight: tab === 'project' ? "bold" : "normal",
						}}
					/>
					<Tab
						value="operations"
						label="OPERATIONS"
						onClick={() => setIsOperationsModalOpen(true)}
						style={{
							color: "gray",
							textTransform: "none",
							fontWeight: "normal",
							pointerEvents: "auto",
							opacity: 0.5,
						}}
						/>
					{/* Operations pop-up */}
					<Dialog
						open={isOperationsModalOpen}
						onClose={() => setIsOperationsModalOpen(false)}
						aria-labelledby="coming-soon-dialog-title"
					>
						<DialogTitle className="titillium-web-base" style={{backgroundColor: '#093d61', color: 'white', maxHeight: '40px'}}>Coming Soon</DialogTitle>
						<DialogContent className="titillium-web-base" style={{paddingTop: '20px', paddingBottom: 'auto'}}>
							The Operations tab is coming soon. Stay tuned! ✌️
							<br></br>
							<img src={catGif} alt="cat working" style={{maxHeight: '150px', borderRadius: '10px', display: 'block', marginTop: '15px', marginLeft: 'auto', marginRight: 'auto'}} />
						</DialogContent>
						<DialogActions>
						<Button onClick={() => setIsOperationsModalOpen(false)} color="secondary" className="standard-secondary-button">
							Close
						</Button>
						</DialogActions>
					</Dialog>
				</Tabs>
			</div>

			<div className="header-upper-section title-block-content">
				<div className="dashboard-title" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start', flexDirection: 'column', gap: '1px'}}>				
					<div className="dashboard-selectors-container">
						{/* Project View */}
						{tab === 'project' && (
							<>
								<div className='dashboard-selector' style={{ flex: 1 }}>
									<CompanySelect
										selectedCompany={selectedCompany}
										options={companies}
										handleSelectChange={handleCompanySelectChangeForProjectView}
									/>
								</div>
								<div className='dashboard-selector' style={{ flex: 1 }}>
									<ProjectSelect />
								</div>
							</>
						)}
						{/* Portfolio View */}
						{tab === 'portfolio' && (
							<>
								<div className='dashboard-selector' style={{ flex: 1 }}>
									<PortfolioCompanySelect
										selectedCompany={selectedPortfolioCompany}
										options={companies}
										handleCompanySelectChangeForPortfolioView={handleCompanySelectChangeForPortfolioView}
										onChange={(newValues) => {
											setSelectedPortfolioCompany(newValues);
										}}
									/>
								</div>
								<div className='dashboard-selector' style={{ flex: 1 }}>
									<DivisionSelect
										options={divisionOptions}
										selectedValues={selectedDivisions}
										onChange={(newValues) => {
											setSelectedDivisions(newValues);
										}}
									/>
								</div>
								<div className='dashboard-selector' style={{ flex: 1 }}>
									<ProjectStageSelect
										options={stageOptions}
										selectedValues={selectedStages}
										onChange={(newValues) => {
											setSelectedStages(newValues);
										}}
									/>
								</div>
								<div className='dashboard-selector' style={{ flex: 1 }}>
									<OpsLeaderSelect
										options={opsLeaderOptions}
										selectedValues={selectedOpsLeaders}
										onChange={(newValues) => {
											setSelectedOpsLeaders(newValues);
										}}
									/>
								</div>
								<div className='dashboard-selector' style={{ flex: 1 }}>
									<ProjectTypeSelect
										options={projectTypeOptions}
										selectedValues={selectedProjectTypes}
										onChange={(newValues) => {
											setSelectedProjectTypes(newValues);
										}}
									/>
								</div>
								<div className='dashboard-selector' style={{ flex: 1 }}>
									<AccountSelect
										options={accountOptions}
										selectedValues={selectedAccounts}
										onChange={(newValues) => {
											setSelectedAccounts(newValues);
										}}
									/>
								</div>						
								<div className="csr-report-link-container" style={{ alignSelf: 'center' }}>
									{selectedPortfolioCompany.length > 0 ? (
										<div style={{display: 'flex', flexDirection:'row', alignItems: 'center', justifyContent:'center'}}>
											<a href={`https://hub.arcomurray.com/reports/confidentiality/view/?company=${selectedPortfolioCompany}`} className="csr-report-link" target="_blank" rel="noopener noreferrer">
												Confidentiality Sales Report
												<OpenInNewIcon style={{ marginLeft: '5px', fontSize: '20px', verticalAlign: 'middle' }} />
											</a>
										</div>
									) : (
										null
									)}
								</div>
							</>
						)}
					</div>
				</div>
				
				{tab==='project' && (
					selectedProject ? (
						<div className="project-information-container" style={{marginTop: '10px'}}>
							<Typography variant="h6" className="titillium-web-base project-header-title-text-large">
							{selectedProject.name} | {projectData.project_data?.account_name? projectData.project_data.account_name : '--'}
							</Typography>
							{/* <Typography className="titillium-web-base project-header-title-text">
							</Typography> */}
							<div style={{ display: "flex", alignItems: "center", justifyContent: "center", gap: "8px" }}>
								<Typography className="titillium-web-base project-header-title-text">
									ARCO ID: {selectedProject.arco_id}
								</Typography>
								{/* Badge Logic */}
								{settingsData?.other_meta_data?.contract_structure === "GMP" && (
									<div
										className="badge titillium-web-base project-header-title-text-small"
										style={{
											backgroundColor: "green",
											color: "white",
											padding: "0px 5px",
											borderRadius: "5px",
											fontSize: '0.75rem'
										}}
									>
										GMP
									</div>
								)}
								{settingsData?.other_meta_data?.contract_structure === "Lump Sum" && (
									<div
										className="badge titillium-web-base project-header-title-text-small"
										style={{
											backgroundColor: "purple",
											color: "white",
											padding: "0px 5px",
											borderRadius: "5px",
											fontSize: '0.75rem'
										}}
									>
										Lump Sum
									</div>
								)}
							</div>
						</div>
					) : (
						<div
							className="project-information-container"
							style={{ marginBottom: "20px" }}
						>
							<Typography
								variant="h6"
								className="titillium-web-base project-header-title-text"
								style={{
									marginTop: "20px",
									textAlign: "center",
								}}
							>
								Please Select a Project to View Data.
							</Typography>
						</div>
					)
				)}
				{tab==='project' && selectedProject && (
					<div className="project-links-container" style={{ flex: 1 }}>
						{selectedProject && selectedSnapshots && selectedSnapshots.length > 0 && mostRecentCreatedAt && (
							<Typography variant='h6' className="titillium-web-base project-header-title-text">
								Latest Budget Snapshot: {mostRecentCreatedAt}
							</Typography>
						)}

						{selectedProject && (
						<div className="bonus-tool-container" style={{ display: 'none' }}>
							<Typography variant='h6' className="titillium-web-base">
								Bonus Model
							</Typography>
							<Link href='#' target="_blank" variant='h6' className="titillium-web-base">
								<img src={bonusToolIcon} className="bonus-tool-icon" />
							</Link>
						</div>
						)}
						{selectedProject && (
						<div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', marginBottom: '-8px', marginTop: '-5px', marginRight: '-8px' }}>
							<ProjectSettingsModal
								Component={BudgetSnapshotSelector}
								settingsData={settingsData}
								initialSnapshots={initialSnapshots}
								selectedSnapshots={selectedSnapshots}
								setTeamData={setTeamData} 
								setBudgetData={setBudgetData} 
								setProjectData={setProjectData}
								setSettingsData={setSettingsData}
								>
							</ProjectSettingsModal>
						</div>
						)}
						<div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'baseline' }}>
							<FAQModal 
								title={'Frequently Asked Questions'}
							/>
							|
							<TutorialModal 
								title={'Guide to using the Project Health Dashboard'}
							/>
						</div>
					</div>
				)}
				{tab==='project' && !selectedProject && (
					<div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'baseline' }}>
						<FAQModal 
							title={'Frequently Asked Questions'}
						/>
						|
						<TutorialModal 
							title={'Guide to using the Project Health Dashboard'}
						/>
					</div>			
				)}
			</div>
		</CardContent>
	);
};

export default HeaderComponent;