import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    filters: [],
    selectedPortfolioCompany: [],
    portfolioItems: [],
    divisionOptions: [],
    selectedDivisions: [],
    stageOptions: [],
    selectedStages: [],
    opsLeaderOptions: [],
    selectedOpsLeaders: [],
    projectTypeOptions: [],
    selectedProjectTypes: [],
    accountOptions: [],
    selectedAccounts: [],
    portfolioCompanies: [],
    sortField: 'project_name', // Sort by name as default
    pageNumber: 1,
    totalResults: 0,
    hasMoreResults: false,
    portfolioError: null,
    prevPageExists: false,
    nextPageExists: false,
    resultsPerPage: 25,
    showSortOptions: false,
    portfolioTeamData: [],
    portfolioProjects: [],
};

const portfolioSlice = createSlice({
    name: 'portfolio',
    initialState,
    reducers: {
        setFilters(state, action) {
            state.filters = action.payload;
        },
        setSelectedPortfolioCompany(state, action) {
            state.selectedPortfolioCompany = action.payload;
        },
        setPortfolioItems(state, action) {
            state.portfolioItems = action.payload;
        },
        addPortfolioItem(state, action) {
            state.portfolioItems.push(action.payload);
        },
        removePortfolioItem(state, action) {
            state.portfolioItems = state.portfolioItems.filter(
                item => item.id !== action.payload
            );
        },
        setDivisionOptions(state, action) {
            state.divisionOptions = action.payload;
        },
        setSelectedDivisions(state, action) {
            state.selectedDivisions = action.payload;
        },
        setStageOptions(state, action) {
            state.stageOptions = action.payload;
        },
        setSelectedStages(state, action) {
            state.selectedStages = action.payload;
        },
        setOpsLeaderOptions(state, action) {
            state.opsLeaderOptions = action.payload;
        },
        setSelectedOpsLeaders(state, action) {
            state.selectedOpsLeaders = action.payload;
        },
        setProjectTypeOptions(state, action) {
            state.projectTypeOptions = action.payload;
        },
        setSelectedProjectTypes(state, action) {
            state.selectedProjectTypes = action.payload;
        },
        setAccountOptions(state, action) {
            state.accountOptions = action.payload;
        },
        setSelectedAccounts(state, action) {
            state.selectedAccounts = action.payload;
        },
        setPortfolioCompanies(state, action) {
            state.portfolioCompanies = action.payload;
        },
        setSortField(state, action) {
            state.sortField = action.payload;
        },
        setPageNumber(state, action) {
            state.pageNumber = action.payload;
        },
        setTotalResults(state, action) {
            state.totalResults = action.payload;
        },
        setHasMoreResults(state, action) {
            state.hasMoreResults = action.payload;
        },
        setPortfolioError(state, action) {
            state.portfolioError = action.payload;
        },
        setPrevPageExists(state, action) {
            state.prevPageExists = action.payload;
        },
        setNextPageExists(state, action) {
            state.nextPageExists = action.payload;
        },
        setShowSortOptions(state, action) {
            state.showSortOptions = action.payload;
        },
        setTeamPortfolioData(state, action) {
            state.teamData = action.payload;
        },
        setPortfolioProjects(state, action) {
            state.portfolioProjects = action.payload;
        },
    },
});

export const {
    setFilters,
    setSelectedPortfolioCompany,
    setPortfolioItems,
    addPortfolioItem,
    removePortfolioItem,
    setDivisionOptions,
    setSelectedDivisions,
    setStageOptions,
    setSelectedStages,
    setOpsLeaderOptions,
    setSelectedOpsLeaders,
    setProjectTypeOptions,
    setSelectedProjectTypes,
    setAccountOptions,
    setSelectedAccounts,
    setPortfolioCompanies,
    setSortField,
    setPageNumber,
    setTotalResults,
    setHasMoreResults,
    setPortfolioError,
    setPrevPageExists,
    setNextPageExists,  
    setShowSortOptions,
    setPortfolioTeamData,
    setPortfolioProjects,
} = portfolioSlice.actions;

export default portfolioSlice.reducer;