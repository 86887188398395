import React, { useState } from 'react';
import { Typography, Link } from '@mui/material';
import { makeStyles } from '@material-ui/core';
import { fontFamily } from '@mui/system';
import scribeLogo from '../../static/scribeLogo2.svg'

// ====== Update Here Once Scribe is FINSIHED ==========
const scribeURL = `https://scribehow.com/shared/Project_Health_Dashboard_PHD__QIjB8iH3Tsq8igtQYw0UgA`


const useStyles = makeStyles((theme) => ({
    root: {
      width: '95%',
      marginLeft: 'auto',
      marginRight: 'auto',
    },
    heading: {
      fontSize: theme.typography.pxToRem(18),
      fontWeight: theme.typography.fontWeightBold,
    },
    accordion: {
        margin: theme.spacing(1, 0),
      },
  }));
     
const TutorialModal = ({ title }) => {
    const classes = useStyles();

    return (
        <>
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <Link
          id="link-5"
          href={scribeURL}
          target="_blank"
          rel="noopener noreferrer"
          style={{
            display: 'flex',
            alignItems: 'center',
            textDecoration: 'none',
          }}
        >
          <Typography
            variant="subtitle1"
            className="card-title"
            style={{
              color: 'white',
              textDecoration: 'underline',
              cursor: 'pointer',
              fontSize: '1rem',
              marginLeft: '8px',
              marginRight: '8px',
            }}
          >
            Tutorial
          </Typography>

          <img
            src={scribeLogo}
            className="project-link-icon"
            alt="Scribe"
            title="Open Scribe for PHD"
            style={{
                verticalAlign: 'middle',
                marginLeft: '-2px'
            }}
          />
        </Link>
      </div>
    </>
  );
};

export default TutorialModal;
