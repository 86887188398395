import React from "react";
import { TextField, Grid, FormHelperText, Typography } from "@mui/material";
import { formatDollarFull } from "../utilityFunctions";

const RevenueSpreadSettingsFields = ({ revSpreadFields, setRevSpreadFields, percentTotalError, setPercentTotalError }) => {
	const today = new Date();
	let year = today.getFullYear();
	let month = today.getMonth() + 1;
	if (month === 1) {
		year -= 1;
	}

	const unearned_revenue = revSpreadFields.sfdc_total_revenue - revSpreadFields.sfdc_off_the_books_revenue;
	const rev_this_year = formatDollarFull(unearned_revenue * (revSpreadFields.sfdc_percent_revenue_this_year / 100));
	const rev_second_year = formatDollarFull(unearned_revenue * (revSpreadFields.sfdc_percent_revenue_second_year / 100));
	const rev_third_year = formatDollarFull(unearned_revenue * (revSpreadFields.sfdc_percent_revenue_third_year / 100));

	const handleFieldChange = (event) => {
		const { name, value, type, checked } = event.target;
		const newValue = type === "checkbox" ? checked : value;

		const temp = { ...revSpreadFields, [name]: newValue };
		const a = parseFloat(temp.sfdc_percent_revenue_this_year);
		const b = parseFloat(temp.sfdc_percent_revenue_second_year);
		const c = parseFloat(temp.sfdc_percent_revenue_third_year);

		setPercentTotalError(a + b + c !== 100 || a < 0 || b < 0 || c < 0);
		setRevSpreadFields(temp);
	};

	const fieldConfigs = [
		{ label: "Total Revenue", name: "sfdc_total_revenue", value: formatDollarFull(revSpreadFields.sfdc_total_revenue), disabled: true },
		{ label: "Earned in Prior Years", name: "sfdc_off_the_books_revenue", value: formatDollarFull(revSpreadFields.sfdc_off_the_books_revenue), disabled: true },
		{ label: "Unearned Revenue", name: "unearned_revenue", value: formatDollarFull(unearned_revenue), disabled: true },
		{ label: `${year} Revenue %`, name: "sfdc_percent_revenue_this_year", value: revSpreadFields.sfdc_percent_revenue_this_year, helperText: `Equivalent to: ${rev_this_year}` },
		{ label: `${year + 1} Revenue %`, name: "sfdc_percent_revenue_second_year", value: revSpreadFields.sfdc_percent_revenue_second_year, helperText: `Equivalent to: ${rev_second_year}` },
		{ label: `${year + 2} Revenue %`, name: "sfdc_percent_revenue_third_year", value: revSpreadFields.sfdc_percent_revenue_third_year, helperText: `Equivalent to: ${rev_third_year}` },
	];

	return (
		<div style={{ display: "flex", flexDirection: "column", gap: "16px", margin: "20px", justifyContent: "center", alignItems: "center" }}>
			<Grid container spacing={2}>
				{fieldConfigs.map((config, index) => (
					<Grid item xs={12} md={4} key={index}>
						<TextField
							label={config.label}
							variant="outlined"
							name={config.name}
							value={config.value}
							onChange={handleFieldChange}
							type={config.disabled ? "text" : "number"}
							InputProps={{
								inputProps: config.disabled ? {} : { min: -100, max: 100 },
								endAdornment: config.disabled ? null : <span>%</span>,
							}}
							disabled={config.disabled}
							fullWidth
						/>
						{config.helperText && <FormHelperText>{config.helperText}</FormHelperText>}
					</Grid>
				))}
			</Grid>
			<br />
			{percentTotalError && <Typography style={{ color: 'red' }}>Error: Revenue Spread Percentages must be positive and should all add up to 100%</Typography>}
		</div>
	);
};

export default RevenueSpreadSettingsFields;
