import React, { useEffect, useState } from "react";
import { Container, Grid } from "@mui/material";
import { HUB_URL } from "../../../utils/config/config";
import { useSelector, useDispatch } from "react-redux";
import {
  setCsrfToken,
  setAuthenticated,
  setHubDomain,
  setTab,
  setProjectId,
  setApiHeader,
  setApiKey,
  setIsLoading,
} from "../../../store/slices/globalSlice";
import { getHeaders } from "./continerUtilityFunctions";
import PortfolioDashboard from "./portfolio_dashboard";
import { authCheck, fetchProjectsAndCompanies } from "./api";
import HeaderComponent from "../Components/HeaderComponent";

import {
  setDivisionOptions,
  setSelectedDivisions,
  setStageOptions,
  setSelectedStages,
  setOpsLeaderOptions,
  setSelectedOpsLeaders,
  setProjectTypeOptions,
  setSelectedProjectTypes,
  setAccountOptions,
  setSelectedAccounts,
  setPortfolioCompanies,
  setSelectedPortfolioCompany,
  setSortField,
  setPageNumber,
  setTotalResults,
  setHasMoreResults,
  setPortfolioError,
  setPrevPageExists,
  setNextPageExists,
  setPortfolioProjects,
  setPortfolioTeamData,
} from "../../../store/slices/portfolioSlice";

const API_AUTH_HEADER = process.env.REACT_APP_API_AUTH_HEADER;
const API_KEY = process.env.REACT_APP_API_KEY;
const GOOGLE_API_KEY = process.env.REACT_APP_GOOGLE_API_KEY;

const useReduxSelectors = () => {
  const csrfToken = useSelector((state) => state.global.csrfToken);
  const authenticated = useSelector((state) => state.global.authenticated);
  const tab = useSelector((state) => state.global.tab);
  const projectId = useSelector((state) => state.project.projectId);
  const hubDomain = useSelector((state) => state.global.hubDomain);
  const isLoading = useSelector((state) => state.global.isLoading);
  return { csrfToken, authenticated, tab, projectId, hubDomain, isLoading };
};

const PortfolioView = () => {
  const dispatch = useDispatch();

  const {
    divisionOptions,
    selectedDivisions,
    stageOptions,
    selectedStages,
    opsLeaderOptions,
    selectedOpsLeaders,
    projectTypeOptions,
    selectedProjectTypes,
    accountOptions,
    selectedAccounts,
    portfolioCompanies,
    selectedPortfolioCompany,
    sortField,
    pageNumber,
    totalResults,
    hasMoreResults,
    portfolioError,
    prevPageExists,
    nextPageExists,
    resultsPerPage,
    portfolioProjects,
  } = useSelector((state) => state.portfolio);

  // Handle selection change -- Portfolio View
  const handleCompanySelectChangeForPortfolioView = (event, newValue) => {
    dispatch(setSelectedPortfolioCompany(newValue));
  };

  const {
    csrfToken,
    authenticated,
    tab,
    projectId,
    hubDomain,
    apiHeader,
    apiKey,
    isLoading,
  } = useReduxSelectors();
  dispatch(setApiHeader(API_AUTH_HEADER));
  dispatch(setApiKey(API_KEY));

  useEffect(() => {
    if (tab === "portfolio") {
      if (selectedPortfolioCompany?.length === 0) {
        console.log(
          "No company selected, resetting filters or making API call"
        );
        dispatch(setPageNumber(1));
        handlePortfolioSearch();
      } else {
        console.log("Selected company updated:", selectedPortfolioCompany);
        dispatch(setPageNumber(1));
        handlePortfolioSearch();
      }
    }
  }, [selectedPortfolioCompany, tab]);

  // UseEffect to call Portfolio Search when any of the dropdowns are updated
  useEffect(() => {
    if (tab === "portfolio") {
      // Check if filters were updated
      const filtersSelected =
        selectedPortfolioCompany?.length > 0 ||
        (selectedDivisions && selectedDivisions.length > 0) ||
        (selectedStages && selectedStages.length > 0) ||
        (selectedOpsLeaders && selectedOpsLeaders.length > 0) ||
        (selectedProjectTypes && selectedProjectTypes.length > 0) ||
        (selectedAccounts && selectedAccounts.length > 0);

      if (filtersSelected && pageNumber !== 1) {
        // Reset to page 1 when any filter is selected
        setPageNumber(1);
      } else {
        // Clear projects and set loading state for any other trigger
        dispatch(setIsLoading(true));
        handlePortfolioSearch();
      }
    }
  }, [
    tab,
    selectedPortfolioCompany,
    selectedDivisions,
    selectedStages,
    selectedOpsLeaders,
    selectedProjectTypes,
    selectedAccounts,
    sortField,
  ]);

  // Breaking out this useEffect to handle pageNumber changes
  useEffect(() => {
    if (tab === "portfolio") {
      dispatch(setIsLoading(true));
      handlePortfolioSearch();
    }
  }, [pageNumber]);

  // Listen for messages from parent to set CSRF token
  useEffect(() => {
    const messageListener = (event) => {
      if (event.origin === HUB_URL) {
        let local_tab = event.data.tab === "None" ? "project" : event.data.tab;
        dispatch(setTab(local_tab));
        dispatch(setProjectId(event.data.project_id));
        dispatch(setHubDomain(event.data.current_domain));
        dispatch(setCsrfToken(event.data.token));
      }
    };

    window.addEventListener("message", messageListener);

    return () => {
      window.removeEventListener("message", messageListener);
    };
  }, []);

  // ==================================================
  // ========= Fetching for Portfolio View ============
  // ==================================================
  const handlePortfolioSearch = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const user_uid = urlParams.get("user_uid");

    // Make sure we have a user_uid and we're in portfolioView mode
    if (user_uid && tab === "portfolio") {
      // Clear projects list and set loading state
      setPortfolioError(null);
      dispatch(setIsLoading(true));
      let headers = getHeaders(csrfToken, API_AUTH_HEADER, API_KEY);

      // First, perform an auth check
      fetch(`${hubDomain}/phd/auth_check/`, {
        method: "POST",
        headers: headers,
        body: JSON.stringify({
          user_uid: user_uid,
          csrf_token: csrfToken,
        }),
        credentials: "include",
      })
        .then((response) => {
          // If non-200 response, set an error message for FE to break isLoading
          if (!response.ok) {
            setPortfolioError(
              `An error occurred while fetching data: Status ${response.status}. Our dev team is on it!`
            );
            dispatch(setIsLoading(false));
            return null;
          }
          return response.json();
        })
        .then((authData) => {
          if (authData.auth_check_passed) {
            dispatch(setAuthenticated(true));

            // Build body for get_pv_data
            const body = {
              csrf_token: csrfToken,
              user_uid: user_uid,
              sorting_order: sortField, // Using the sortField - project_name by default
              page_number: pageNumber,
              company_filter:
              selectedPortfolioCompany?.length > 0
                ? selectedPortfolioCompany.map((company) => company.company) 
                : null,
              division_filter:
                selectedDivisions?.length > 0 ? selectedDivisions : null,
              stage_filter: selectedStages?.length > 0 ? selectedStages : null,
              ops_leader_filter:
                selectedOpsLeaders?.length > 0 ? selectedOpsLeaders : null,
              project_type_filter:
                selectedProjectTypes?.length > 0 ? selectedProjectTypes : null,
              account_filter:
                selectedAccounts?.length > 0 ? selectedAccounts : null,
            };
            // call get_pv_data for proj list
            fetch(`${hubDomain}/phd/get_pv_data/`, {
              method: "POST",
              headers: headers,
              body: JSON.stringify(body),
              credentials: "include",
            })
              .then((response) => {
                // If non-200 response, set an error message for FE to break isLoading
                if (!response.ok) {
                  setPortfolioError(
                    `Error fetching data: Status ${response.status}). Our dev team is on it!`
                  );
                  dispatch(setIsLoading(false));
                  return null;
                }
                return response.json();
              })
              .then((data) => {
                if (data) {
                  dispatch(setPortfolioProjects(data.projects || []));
                  dispatch(setTotalResults(data.total_results || 0));
                  dispatch(setPortfolioError(null));
                  dispatch(setIsLoading(false));
                  dispatch(setHasMoreResults(data.more_results || false));
                  dispatch(setPrevPageExists(data.prev_page_exists || false));
                  dispatch(setNextPageExists(data.next_page_exists || false));
                  // Fill out dropdowns
                  if (data.filter_dropdowns) {
                    dispatch(
                      setPortfolioCompanies(
                        data.filter_dropdowns.companies || []
                      )
                    );
                    dispatch(
                      setDivisionOptions(data.filter_dropdowns.divisions || [])
                    );
                    dispatch(
                      setStageOptions(data.filter_dropdowns.stages || [])
                    );
                    dispatch(
                      setOpsLeaderOptions(
                        data.filter_dropdowns.ops_leaders || []
                      )
                    );
                    dispatch(
                      setProjectTypeOptions(
                        data.filter_dropdowns.project_types || []
                      )
                    );
                    dispatch(
                      setAccountOptions(data.filter_dropdowns.accounts || [])
                    );
                  }
                } else {
                  console.error("Failed setting Projects data:", data);
                }
                dispatch(setIsLoading(false));
              })
              .catch((error) => {
                console.error("Error in fetching PV data:", error);
                dispatch(setIsLoading(false));
              });
          } else {
            console.error("Token validation failed:", authData);
            dispatch(setIsLoading(false));
          }
        })
        .catch((error) => {
          console.error("Error during auth check:", error);
          dispatch(setIsLoading(false));
        });
    } else {
      console.log("Portfolio search triggered but user_uid is not available.");
    }
  };

  return (
    <Grid item xs={12} style={{ marginTop: "15px" }}>
      <div className="header-container">
        <HeaderComponent
          today_date={new Date()}
          selectedCompany={selectedPortfolioCompany}
          companies={portfolioCompanies}
          projects={portfolioProjects}
          handleCompanySelectChangeForProjectView={(event, newValue) =>
            dispatch(setSelectedPortfolioCompany(newValue))
          }
          handleProjectSelectChange={(event, newValue) =>
            dispatch(setSelectedPortfolioCompany(newValue))
          }
          handleCompanySelectChangeForPortfolioView={
            handleCompanySelectChangeForPortfolioView
          }
          setSelectedProjectId={(id) =>
            dispatch(setSelectedPortfolioCompany(id))
          }
          divisionOptions={divisionOptions}
          selectedDivisions={selectedDivisions}
          setSelectedDivisions={(divisions) =>
            dispatch(setSelectedDivisions(divisions))
          }
          setSelectedPortfolioCompany={(company) =>
            dispatch(setSelectedPortfolioCompany(company))
          }
          selectedPortfolioCompany={selectedPortfolioCompany}
          stageOptions={stageOptions}
          selectedStages={selectedStages}
          setSelectedStages={(stages) => dispatch(setSelectedStages(stages))}
          opsLeaderOptions={opsLeaderOptions}
          selectedOpsLeaders={selectedOpsLeaders}
          setSelectedOpsLeaders={(leaders) =>
            dispatch(setSelectedOpsLeaders(leaders))
          }
          projectTypeOptions={projectTypeOptions}
          selectedProjectTypes={selectedProjectTypes}
          setSelectedProjectTypes={(types) =>
            dispatch(setSelectedProjectTypes(types))
          }
          accountOptions={accountOptions}
          selectedAccounts={selectedAccounts}
          setSelectedAccounts={(accounts) =>
            dispatch(setSelectedAccounts(accounts))
          }
          isLoading={isLoading}
        />
      </div>
      <PortfolioDashboard
        gmapsAPI={GOOGLE_API_KEY}
        tab={tab}
        divisionOptions={divisionOptions}
        selectedDivisions={selectedDivisions}
        setSelectedDivisions={setSelectedDivisions}
        stageOptions={stageOptions}
        selectedStages={selectedStages}
        setSelectedStages={setSelectedStages}
        opsLeaderOptions={opsLeaderOptions}
        selectedOpsLeaders={selectedOpsLeaders}
        setSelectedOpsLeaders={setSelectedOpsLeaders}
        projectTypeOptions={projectTypeOptions}
        selectedProjectTypes={selectedProjectTypes}
        setSelectedProjectTypes={setSelectedProjectTypes}
        accountOptions={accountOptions}
        selectedAccounts={selectedAccounts}
        setSelectedAccounts={setSelectedAccounts}
        selectedCompany={selectedPortfolioCompany}
        setSelectedPortfolioCompany={setSelectedPortfolioCompany}
        onCompanySelect={handleCompanySelectChangeForPortfolioView}
        handlePortfolioSearch={handlePortfolioSearch}
        isLoading={isLoading}
        setIsLoading={setIsLoading}
        sortField={sortField}
        setSortField={setSortField}
        pageNumber={pageNumber}
        setPageNumber={setPageNumber}
        hasMoreResults={hasMoreResults}
        setHasMoreResults={setHasMoreResults}
        resultsPerPage={resultsPerPage}
        totalResults={totalResults}
        prevPageExists={prevPageExists}
        nextPageExists={nextPageExists}
        portfolioError={portfolioError}
      />
    </Grid>
  );
};

export default PortfolioView;
